body {
  background-image: url("../img/aurora-background.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.jumbotron h1,
.jumbotron .h1 {
  color: white;
}
.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  color: white;
  font-weight: 400;
}

iframe {
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
}

.about-tile {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #e1e1e1;
  border-width: 3px;
  border-color: lightgrey;
  border-style: dashed;
  max-width: 400px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.sdg-video {
  aspect-ratio: 16 / 9;
}

.sdg-guide {
  aspect-ratio: 1 / 1;
}

.banner {
  min-height: 300px;
}

@media (min-width: 576px) {
  .banner {
    min-height: 200px;
  }
}


@media (min-width: 992px) {
  .banner {
    min-height: 400px;
  }
}


@media (min-width: 1200px) {
  .banner {
    min-height: 500px;
  }
}

.jumbotron > hr {
  border-top-color: #d5d5d5;
}
.container .jumbotron,
.container-fluid .jumbotron {
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 6px;
}
.jumbotron .container {
  max-width: 100%;
}

.p-submenu-list {
  background-color: whitesmoke !important;
}

.p-menubar {
  background-color: white !important;
  border: none;
}
.p-menubar-root-list {
  background-color: white !important;
}

.p-menuitem-icon {
  color: black !important;
}

@media only screen and (max-width: 600px) {
  .p-menubar-root-list {
      top: 50px;
    left: 0;
  }
}


.p-menubar-button {
  color: black !important;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

span.p-menuitem-text {
  color: black !important;
  font-size: large;
}

@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .container .jumbotron,
  .container-fluid .jumbotron {
    padding-right: 60px;
    padding-left: 60px;
  }
  .jumbotron h1,
  .jumbotron .h1 {
    font-size: 63px;
  }
}
.tile {
  background-color: #49B05C;
  float: left;
  opacity: 0.9;
  color: #ffffff;
  border-radius: 25px;
  height: 100%;
  width: 100%;
  padding: 20px;
  vertical-align: middle;
}

.banner {
  padding: 100px 0 0;
  height: 340px;
}

.tile-top {
  border-radius: 25px 25px 0px 0px;
}

.tile-bottom {
  border-radius: 0px 0px 25px 25px;
}

.description {
  font-size: large;
  text-align:center;
}

h1 {
  text-align: center;
}

h2 {
  text-align: left;
}

.centered {
  text-align: center;
}

.footer-link {
  color: #2b542c;
}

a.footer-link:hover {
  color: #dddddd;
}


.content-start {
  min-height: calc(100vh - 70px);
  font-family: Arial, Helvetica, sans-serif;
}

.content-container {
  margin: 0px;
  padding: 0px;
}
.nav-logo {
  display: inline-block;
  max-height: 56px;
  max-width: 225px;
  padding: 8px 4px 8px 12px;
  vertical-align: middle;
}

nav.nav-text {
  font-family: Lato, sans-serif;
  font-size: 15pt;
  font-weight: 300;
}


.content {
  padding: 50px;
  margin-top: 10px;
  background-color: white;
}

.classify-button  {
  background-color: #00E9BC;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
}

.text-input {
  color: #578bff!important;
  margin-top: 30px;
  margin-bottom: 30px;
}

.results-heading {
  color: #00E9BC!important;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.footer-start {
  background-color: #49B05C;
  float: left;
  opacity: 0.9;
  color: #ffffff;
  width: 100%;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
  font-size: larger;
}

.footer {
  background-color: #49B05C;
  float: left;
  opacity: 0.9;
  color: #ffffff;
  width: 100%;
  padding: 5px;
  vertical-align: middle;
  text-align: center;
  font-size: larger;
  height: 80px;
}

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-bottom: 50px;
}

.navbar {
  background-color: #49B05C;
  opacity: 0.9;
  color: #ffffff;
}

.navbar-brand  {
  color: #ffffff;
  font-size: x-large;
  font-weight: bold;
}

.sdg-wheel {
  position: relative;
}

.sdg-wheel:hover + .sdg-legend {
  opacity: 1;
  background-color: white;
  color: black;
}

.sdg-description {
  display: none;
  width: 200px;
  height: 100px;
}

.sdg-legend {
  border-radius: 20px;
  position: absolute;
  left: 100px;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.8); /* Black see-through */
  width: 400px;
  transition: .5s ease;
  color: white;
  font-size: 14px;
  padding: 10px;
}

button.footer {
  height: 50px;
  width: 200px;
}

textarea.form-tile {
  width: 100%;
  background-color: #59C06C;
  color: white;
  border-width: 0;
}

input.form-tile {
  width: 100%;
  background-color: #59C06C;
  color: white;
  border-width: 0;
}
